<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="护士站智能交互系统" describe="护士站智能交互系统通过放在护士站的智能交互大屏，与病房终端、医院His系统进行数据互联互通，实现院内数据统一整合显示。包括病区概况、患者信息、今日治疗、备忘录、交班信息、呼叫响应、健康宣教、护士排班、危急值预警等信息，帮助医护人员快速获取工作重点，优化工作流程，提高工作效率。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <div class="introduction-item">
          <div class="item-left">
            <img class="img img1" src="@/assets/product/nurse1.png" alt="">
          </div>
          <div class="item-right text-content">
            <div class="title">
              <span class="span1">丰富的</span><span>显示模板</span>
            </div>
            <div class="des">
              大屏首页支持病区概况、护理信息、高危管理、预约检查、特殊用药、患者导管、护理任务、预约手术等信息的自定义显示，包含多种显示风格。根据不同病区的内容要求和风格，可灵活调整显示内容、布局和风格。
            </div>
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left text-content">
            <div class="title">
              <span class="span1">呼叫播放</span>
              <span>及时提醒</span>
            </div>
            <div class="des">
              患者的换药呼叫、紧急呼叫、卫生间报警、护理计时结束等多种呼叫提醒信息同步显示到大屏上，并语音循环播报，提醒护士人员及时跟进护理。
            </div>
          </div>
          <div class="item-right">
            <img class="img img2" src="@/assets/product/nurse2.png" alt="">
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left">
            <img class="img img3" src="@/assets/product/nurse3.png" alt="">
          </div>
          <div class="item-right text-content">
            <div class="title">
              <span class="span1">第三方信息</span><span>对接展示</span>
            </div>
            <div class="des">
              支持与第三方系统对接，实现护理待办事项、危机值、护理不良事件、护理评估情况的系统联动，另外可与病呼系统、体征采集设备等物联网应用连接，方便医护人员在护士站查看实时数据以及智能警报。
            </div>
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left text-content">
            <div class="title">
              <span class="span1">患者信息</span><span>一目了然</span>
            </div>
            <div class="des">
              实时显示患者的床位信息，通过系统可查看患者的详情，包括入院信息、医嘱信息、检查检验报告、护理记录、生命体征等信息。
            </div>
          </div>
          <div class="item-right">
            <img class="img img4" src="@/assets/product/nurse4.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: 'SmartWard',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #f7f8fe;
    .highlight {
      display: flex;
      justify-content: center;
      align-items: center;
      .highlight-item {
        background: #ffffff;
        width: 400px;
        height: 270px;
        padding: 40px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        .item-top {
          display: flex;
          align-items: flex-end;
          margin-bottom: 20px;
          color: #333333;
          line-height: 33px;
          font-size: 24px;
          font-weight: 500;
          img {
            width: 80px;
            height: 60px;
            margin-right: 26px;
          }
        }
        .item-bottom {
          color: #5d7392;
          line-height: 22px;
          font-size: 16px;
        }
      }
    }
    .introduction-content {
      width: 1400px;
      margin: 0 auto;
      padding-bottom: 212px;
      .introduction-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        padding: 0 92px 0 62px;
        height: 540px;
        &:nth-child(2n) {
          background-color:  #eff6ff;
        }
        .img1 {
          width: 615px;
          height: 390px;
        }
        .img2 {
          width: 629px;
          height: 452px;
        }
        .img3 {
          width: 617px;
          height: 447px;
        }
        .img4 {
          width: 567px;
          height: 395px;
        }
        .text-content {
          width: 500px;
          .title {
            font-weight: 500;
            color: #333;
            line-height: 45px;
            font-size: 32px;
            margin-bottom: 20px;
            .span1 {
              color: #0279ff;
            }
          }
          .des {
            color: #666666;
            line-height: 22px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
